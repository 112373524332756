<script setup lang="ts">
import { uniqBy } from 'lodash'
import { computed } from 'vue'
import { useRoute, RouterLink } from 'vue-router'

import { useBreadcrumbs } from './use-breadcrumbs'

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '.'

const { getBreadcrumbLabel } = useBreadcrumbs()

const route = useRoute() || {}

const uniqRouteMatched = computed(() => uniqBy(route.matched, 'path'))
</script>

<template>
  <Breadcrumb class="flex items-center" data-e2e="breadcrumbs">
    <BreadcrumbList data-e2e="breadcrumbs-list">
      <template v-for="(link, index) in uniqRouteMatched" :key="link.meta.title">
        <BreadcrumbSeparator v-if="index > 0" />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage
            v-if="index === uniqRouteMatched?.length - 1"
            :data-e2e="`breadcrumbs-list-item-${link.meta.title}`"
          >
            {{ getBreadcrumbLabel(link.meta.title) }}
          </BreadcrumbPage>
          <BreadcrumbLink v-else as-child data-e2e="breadcrumbs-list-item">
            <RouterLink
              :to="link.path"
              class="text-xs font-medium"
              :data-e2e="`breadcrumbs-list-item-${link.meta.title}`"
            >
              {{ getBreadcrumbLabel(link.meta.title) }}
            </RouterLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </template>
      <template v-if="route.query?.folderName">
        <BreadcrumbSeparator />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage :data-e2e="`breadcrumbs-list-item-${route.query?.folderName}`">
            {{ route.query?.folderName }}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </template>
      <template v-if="route.query?.pageName">
        <BreadcrumbSeparator />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage :data-e2e="`breadcrumbs-list-item-${route.query?.pageName}`">
            {{ route.query?.pageName }}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </template>
    </BreadcrumbList>
  </Breadcrumb>
</template>
