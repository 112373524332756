<script setup lang="ts">
import { SelectIcon, SelectTrigger } from 'radix-vue'
import { inject } from 'vue'

import { cn, groupStyleClasses } from '../../utils'

import {
  SelectTriggerIcon,
  selectLabelVariants,
  selectVariants,
  selectWrapperVariants,
  valueToVariant,
  type CustomSelectTriggerProps,
} from './'

const props = withDefaults(defineProps<CustomSelectTriggerProps>(), {
  name: '',
  class: '',
  label: '',
  invalid: false,
  error: '',
  value: undefined,
  triggerClasses: '',
  wrapperClasses: '',
  dataE2e: '',
  color: 'beige',
  required: false,
  hideErrorIcon: false,
})

const isInputGroup = inject<boolean>('input-group', false)

const modelValue = defineModel<string | undefined>()

const onClear = () => {
  modelValue.value = ''
}
</script>

<template>
  <div
    class="relative flex items-center"
    :class="[
      cn(
        selectWrapperVariants({ color }),
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-text opacity-100',
        wrapperClasses ?? '',
        isInputGroup ? '!border-r-0 !border-transparent' : '',
        !isInputGroup && open ? 'border-primary-500' : '',
        !isInputGroup && error ? 'border-error-650' : '',
        groupStyleClasses(isInputGroup!)
      ),
      props.invalid ? 'ring-2 !ring-destructive placeholder:!text-destructive' : '',
    ]"
  >
    <SelectTrigger
      :class="
        selectVariants({
          color,
          label: valueToVariant(label),
          disabled: valueToVariant(disabled),
          class: triggerClasses ?? '',
        })
      "
      v-bind="dataE2e ? { 'data-e2e': dataE2e, ...props } : { ...props }"
    >
      <slot />
      <SelectIcon v-if="!clearable || (clearable && !modelValue)" as-child>
        <div class="absolute right-4 top-1/2 ml-auto flex -translate-y-1/2 transform items-center space-x-2">
          <SelectTriggerIcon v-bind="props" />
        </div>
      </SelectIcon>
    </SelectTrigger>

    <button
      v-if="clearable && modelValue"
      type="button"
      class="absolute right-4 top-1/2 flex -translate-y-1/2 items-center justify-center"
      @click="onClear"
    >
      <INolasXCircle width="16" height="16" />
    </button>

    <label
      v-if="label"
      :for="name"
      :class="
        selectLabelVariants({
          modelValue: valueToVariant(modelValue),
          disabled: valueToVariant(disabled),
          error: valueToVariant(error),
        })
      "
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
  </div>
</template>
