<script setup lang="ts">
import { lowerCase } from 'lodash'
import type { SelectRootEmits } from 'radix-vue'
import { SelectRoot, useForwardPropsEmits } from 'radix-vue'

import { cn } from '../../utils'

import type { SelectProps } from './'
import { SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, SelectLabel } from './'

type Emits = SelectRootEmits & {
  create: []
}
const props = defineProps<SelectProps>()
const emits = defineEmits<Emits>()
const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <SelectRoot v-bind="forwarded" :class="[$attrs.class ?? '']">
    <SelectTrigger
      :name="name!"
      :model-value="modelValue"
      :open="open!"
      :error="error"
      :trigger-classes="triggerClasses"
      :wrapper-classes="wrapperClasses"
      :color="color"
      :label="label"
      :disabled="disabled"
      :data-e2e="dataE2e"
      :required="required"
      :clearable="clearable"
      @update:model-value="emits('update:modelValue', $event || '')"
    >
      <div :class="cn('transition-all duration-75', props.modelValue ? 'visible' : 'invisible', props.iconClasses)">
        <slot name="icon" />
      </div>

      <SelectValue
        placeholder=""
        :class="['flex items-center gap-1.5 truncate', props.modelValue ? 'flex-1 text-gray-900' : 'text-gray-600']"
      />
    </SelectTrigger>
    <SelectContent :class="cn('max-h-96', props.contentClasses)">
      <SelectGroup>
        <template v-for="group in items" :key="`${group}`">
          <template v-if="'title' in group">
            <SelectLabel v-if="group.title" class="mt-2.5 truncate">
              <p class="truncate">{{ group.title }}</p>
            </SelectLabel>

            <SelectItem
              v-for="item in group.items"
              :key="`${item.label}-${item.value}`"
              :value="item.value"
              :data-e2e="item?.e2e ? item?.e2e : `select-${name}-${lowerCase(item.value)}`"
              class="cursor-pointer truncate"
            >
              <component
                :is="item.icon"
                v-if="item.icon"
                :class="cn('h-6 w-6 min-w-6 text-gray-400', props.iconClasses)"
              />
              <p class="truncate">{{ translatable ? $t(item.label) : item.label }}</p>
            </SelectItem>
          </template>

          <template v-else>
            <SelectItem
              :value="group.value"
              :data-e2e="group?.e2e ? group?.e2e : `select-${name}-${lowerCase(group.value)}`"
              class="cursor-pointer truncate"
            >
              <component
                :is="group.icon"
                v-if="group.icon"
                :class="cn('h-6 w-6 min-w-6 text-gray-400', props.iconClasses)"
              />
              <p class="truncate">{{ translatable ? $t(group.label) : group.label }}</p>
            </SelectItem>
          </template>
        </template>
      </SelectGroup>
    </SelectContent>
  </SelectRoot>
</template>
