import { AnyPgColumn, integer, jsonb, pgEnum, pgTable, text, varchar, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $documentId, DocumentID, WorkflowInterfaceID, WorkflowRunID } from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'
import { EInvoiceJSON } from './documents-einvoice.type'
import { workflowInterfaces } from './workflow-interfaces'

/** @see Document.metadata.registeredMail */
export const ShippingMethodDPAG = {
  // Letter - 0b0000_0000
  letter: '0',

  // Priority letter - 0b0000_0001
  priorityLetter: '1',

  // Registered mail posting - 0b0000_0010
  registeredMailPosting: '2',

  // Registered mail handover - 0b0000_0011
  registeredMailHandover: '3',

  // EU + Personal delivery - 0b0001_0011
  euPersonalDelivery: '19',

  // EU + Return receipt - 0b0010_0011
  euReturnReceipt: '35',

  // EU + Personal delivery + Return receipt - 0b0011_0011
  euBoth: '51',
} as const
export type ShippingMethodDPAG = (typeof ShippingMethodDPAG)[keyof typeof ShippingMethodDPAG]

const Attachments = z.array(
  z.object({
    name: z.string(),
    key: z.string(),
  })
)

export const Thumbnails = z.array(
  z.object({
    index: z.number(),
    key: z.string(),
  })
)

export const DecomposedAddress = z
  .object({
    addressType: z.string(),
    companyName: z.string(),
    companySubName: z.string(),
    department: z.string(),
    contactPersonFullName: z.string(),
    contactPersonSalutation: z.string(),
    contactPersonFirstName: z.string(),
    contactPersonLastName: z.string(),
    recipient: z.string(),
    poBox: z.string(),
    email: z.string(),
    phone: z.string(),
    // address fields - same as in address book
    country: z.string(),
    city: z.string(),
    zipCode: z.string(),
    street: z.string(),
    houseNumber: z.string(),
  })
  .partial()

const MergeInfo = z.enum(['mail_merge', 'simple', 'merge', 'split'])
export type MergeInfo = z.infer<typeof MergeInfo>

export const DocumentMetadata = z.object({
  sender: DecomposedAddress.optional(),
  recipient: DecomposedAddress.optional(),
  einvoice: z.custom<EInvoiceJSON>().optional(),
  // TODO: this field needs to be removed.
  mailmerge: z.boolean().optional(),
  decomposed: z
    .array(
      z.object({
        page: z.number(),
        address: DecomposedAddress,
      })
    )
    .optional(),
  mergeInfo: MergeInfo.optional(),
  error: z.string().optional(),
  warnings: z.array(z.string()).optional(),

  // ---- Specific to REST-API interface ---- //
  // Print type - color (true) or black&white (false)
  color: z.boolean().optional(),

  // Print type - duplex (true) or simplex (false)
  duplex: z.boolean().optional(),

  // Position of address window on the first page
  //  - A == Din 5008 Geschäfsbrief Typ A
  //  - B == Din 5008 Geschäfsbrief Typ B
  addressWindow: z.enum(['A', 'B']).optional(),

  // Shipping method, DPAG additional service
  registeredMail: z
    .enum([
      ShippingMethodDPAG.letter,
      ShippingMethodDPAG.priorityLetter,
      ShippingMethodDPAG.registeredMailPosting,
      ShippingMethodDPAG.registeredMailHandover,
      ShippingMethodDPAG.euPersonalDelivery,
      ShippingMethodDPAG.euReturnReceipt,
      ShippingMethodDPAG.euBoth,
    ])
    .optional(),

  // Document ID of an external system
  externalDocId: z.string().optional(),
})

type Attachments = z.infer<typeof Attachments>
export type Thumbnails = z.infer<typeof Thumbnails>
export type DecomposedAddress = z.infer<typeof DecomposedAddress>
export type DocumentMetadata = z.infer<typeof DocumentMetadata>

export const SignatureProperty = z.object({
  pageId: z.number(),
  height: z.number(),
  width: z.number(),
  top: z.number(),
  left: z.number(),
  id: z.string(),
  name: z.string(),
  signatureId: z.string(),
})

export const DocumentLetterhead = z.object({
  label: z.string(),
  value: z.string(),
})

export const PageProperties = z.object({
  color: z.enum(['black_white', 'color']),
  letterhead: z.boolean().optional(),
  signatures: z.record(z.string(), SignatureProperty),
})

export const Sheets = z.object({
  front: z.number(),
  back: z.number().optional(),
})

export const Properties = z.object({
  pageProperties: z.array(PageProperties).optional(),
  //TODO: activeLetterhead will become string only
  activeLetterhead: z.string().or(DocumentLetterhead).optional(),
  sheets: z.array(Sheets).optional(),
})

export type PropertiesType = z.infer<typeof Properties>

export const statusEnum = pgEnum('status_enum', ['PENDING', 'SUCCESS', 'ERROR'])
export type Status = (typeof statusEnum.enumValues)[number]

export const DocumentSubclassification = z
  .object({
    secondaryLabel: z.string(),
    /** @deprecated */
    entities: z.record(z.string(), z.string()),
    userEntities: z.record(z.string(), z.string()),
    systemEntities: z
      .object({
        sender: z.string(),
        recipient: z.string(),
        document_date: z.string(),
      })
      .partial(),
  })
  .partial()
export type DocumentSubclassification = z.infer<typeof DocumentSubclassification>

const StatusName = z.enum(['SUCCESS', 'PENDING', 'ERROR'])
export type StatusName = z.infer<typeof StatusName>

// export const AIStatus = z.object({ name: z.string(), status: StatusName })
// export type AIStatus = z.infer<typeof AIStatus>

export const PreprocessingStepName = z.enum(['CONVERT_TO_PDF', 'DECRYPT', 'NORMALIZE', 'REMOVE_BG', 'PREVIEW'])
export type PreprocessingStepName = z.infer<typeof PreprocessingStepName>
export const PreprocessingSteps = z.array(PreprocessingStepName)

/** These are internal names that will be mapped to the actual names required by the AI queue. */
export const AITaskName = z.enum([
  'BASICS',
  'MAIL_MERGE_ADDRESS_DECOMPOSITION',
  'EINVOICE',
  'SUMMARY',
  'VISUALIZE',
  'READ_INVOICE',
])
export type AITaskName = z.infer<typeof AITaskName>
export const AITasks = z.array(AITaskName)

export type ProcessingStepName = PreprocessingStepName | AITaskName

const ProcessingStep = z.object({
  status: StatusName,
  updatedAt: z.string().datetime(),
  reason: z
    .object({
      name: z.string(),
      description: z.string().optional(),
    })
    .optional(),
})
const GenericStep = ProcessingStep.and(z.object({ name: z.string() }))
const PreprocessingStep = ProcessingStep.and(z.object({ name: PreprocessingStepName }))
const AIProcessingStep = ProcessingStep.and(z.object({ name: AITaskName }))

export const ProcessingStatus = z
  .object({
    generic: z.object({
      status: StatusName,
      executedSteps: z.array(GenericStep),
    }),
    preprocessing: z.object({
      status: StatusName,
      executedSteps: z.array(PreprocessingStep),
    }),
    ai: z.object({
      /** Overall status. */
      status: StatusName,
      executedSteps: z.array(AIProcessingStep),
    }),
  })
  .partial()
export type ProcessingStatus = z.infer<typeof ProcessingStatus>

export const VariantType = z.enum(['EINVOICE_ZUGFERD', 'EINVOICE_UBL', 'EINVOICE_CII', 'OTHER'])
export type VariantType = z.infer<typeof VariantType>

export const Variant = z.object({
  name: z.string(),
  key: z.string(),
  mimeType: z.string(),
  size: z.number(),
  type: VariantType,
  metadata: DocumentMetadata.optional(),
  links: z
    .object({
      download: z.string(),
      thumbnails: z.array(z.string()).optional(),
    })
    .optional(),
  original: z.boolean(),
  hidden: z.boolean().optional(),
})
export type Variant = z.infer<typeof Variant>

export const documents = pgTable('document', {
  id: id<DocumentID>($documentId.prefix),
  parentId: refId<DocumentID>('parent_id').references((): AnyPgColumn => documents.id, { onDelete: 'cascade' }),
  name: text('name').notNull(),
  key: text('key').notNull().unique(),
  mimeType: text('mime_type').notNull(),
  size: integer('size').default(-1).notNull(),
  processingStatus: jsonb('processing_status').$type<z.infer<typeof ProcessingStatus>>(),
  pagesCount: integer('pages_count').default(0),
  workspaceId: text('workspace_id').notNull().$type<WorkspaceID>(),
  createdBy: text('created_by').notNull().$type<UserID>(),
  attachments: jsonb('attachments').$type<Attachments>().default([]),
  thumbnails: jsonb('thumbnails').$type<Thumbnails>().default([]),
  subject: varchar('subject'),
  classification: text('classification'),
  subclassification: jsonb('subclassification').$type<DocumentSubclassification>(),
  summary: text('summary'),
  metadata: jsonb('metadata').$type<DocumentMetadata>(),
  properties: jsonb('properties').$type<PropertiesType>().notNull().default({}),
  uploadedAt: timestamp('uploaded_at'),
  htmlContentPages: jsonb('html_content_pages').$type<string[]>(),

  variants: jsonb('variants').$type<Variant[]>().notNull().default([]),

  // Reference to the Interface used for uploading the document
  workflowInterfaceId: refId<WorkflowInterfaceID>('workflow_interface_id').references(() => workflowInterfaces.id, {
    onDelete: 'cascade',
  }),
  workflowRunId: refId<WorkflowRunID>('workflow_run_id'),

  ...timestamps(),
})

const DocumentSchema = {
  id: DocumentID,
  parentId: DocumentID.nullable(),
  workspaceId: WorkspaceID,
  createdBy: UserID,
  attachments: Attachments,
  thumbnails: Thumbnails,
  properties: Properties,
  metadata: DocumentMetadata.optional(),
  subclassification: DocumentSubclassification.optional(),
  htmlContentPages: z.array(z.string()),
  workflowInterfaceId: WorkflowInterfaceID.optional(),
  workflowRunId: WorkflowRunID.nullable(),
  processingStatus: ProcessingStatus.nullable(),
  variants: z.array(Variant),
}
export const DocumentRecord = createSelectSchema(documents, DocumentSchema)
export type DocumentRecord = z.infer<typeof DocumentRecord>

export const DocumentRecordCreate = createInsertSchema(documents, DocumentSchema)
export type DocumentRecordCreate = z.infer<typeof DocumentRecordCreate>
