<script setup lang="ts">
import NavigationFooter from '@components/navigation-footer.vue'
import OnboardingModal from '@components/onboarding-modal.vue'
import { useClipboard } from '@vueuse/core'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { Button } from '@epostbox/ui/button'
import { SidebarNavigation } from '@epostbox/ui/organisms/sidebar-navigation'
import { Paragraph } from '@epostbox/ui/paragraph'

import { useGetOnboardingData } from '@composables/onboarding/use-get-onboarding'
import { useDocumentSearch } from '@composables/search/use-search'
import { useAuthFlow } from '@composables/use-auth-flow'
import { useUser } from '@composables/use-user'

const { isFetchedAfterMount: isFetchedAfterMountDocuments } = useDocumentSearch({
  space: 'INBOX',
  filterByExclude: { status: 'PENDING' },
})
const route = useRoute()
const { locale, t } = useI18n()
const { copy } = useClipboard()

const { state, redirectedUrl } = useAuthFlow()
const { isFetchedAfterMount, user } = useUser()
const { isOnboardingModalOpen } = useGetOnboardingData()

const sidebarWidth = ref()

const isFullpage = computed(() => {
  return route?.meta.fullpage || false
})

const version = {
  build: import.meta.env.VITE_APP_BUILD_VERSION,
  date: import.meta.env.VITE_APP_BUILD_DATE,
}

onMounted(() => {
  if (!localStorage.getItem('nolas_lang')) {
    localStorage.setItem('nolas_lang', 'de')
  }
})

watchEffect(() => {
  isOnboardingModalOpen.value = user.value?.onboardingStatus === 'required' ? true : false
})

const onClickAvatar = () => {
  if (user.value?.onboardingStatus === 'optional') {
    isOnboardingModalOpen.value = true
  }
}
</script>

<template>
  <div :key="locale" class="w-min-0 w-full grow overflow-hidden">
    <!-- loading skeleton -->
    <div
      v-show="state === 'pending' || !isFetchedAfterMount || !isFetchedAfterMountDocuments"
      class="flex h-screen flex-row items-center justify-center overflow-hidden bg-white"
    >
      <INolasSpinnerGradient class="!h-14 !w-14 text-[#2D2D2D]" />

      <!-- shows up only in the desktop app. -->
      <div v-if="state === 'redirected'" class="mt-8 flex flex-col gap-y-4">
        <Paragraph>
          {{ t('auth.redirected.message') }}
        </Paragraph>
        <Button type="button" data-e2e="copy-redirect" @click="copy(redirectedUrl!)">
          {{ t('auth.redirected.copy') }}
        </Button>
      </div>
    </div>

    <div
      v-if="
        user && ((state !== 'pending' && state !== 'redirected') || isFetchedAfterMount || isFetchedAfterMountDocuments)
      "
      id="app-view"
      class="flex h-screen flex-row bg-white font-bold"
    >
      <OnboardingModal v-model:open="isOnboardingModalOpen" @close="() => (isOnboardingModalOpen = false)" />
      <SidebarNavigation
        v-if="!isFullpage"
        :key="route.path"
        :width="sidebarWidth"
        @update:width="newWidth => (sidebarWidth = newWidth)"
      >
        <template #footer>
          <div class="mt-auto">
            <NavigationFooter :version="version" @avatar-click="onClickAvatar" />
          </div>
        </template>
      </SidebarNavigation>

      <section
        class="flex h-screen w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white transition-all duration-300 ease-in-out"
      >
        <slot />
      </section>
    </div>
  </div>
</template>

<style>
.loader {
  fill: conic-gradient(from 180deg at 50% 50%, #ffffff 0deg, #2d2d2d 360deg) !important;
}
</style>
