import { RouteRecordRaw } from 'vue-router'

import Index from './views/index.vue'

export const routes: RouteRecordRaw = {
  path: '/workbench',
  component: Index,
  name: 'workbench',
  meta: {
    title: 'workbench',
    module: 'workbench',
  },
  children: [
    {
      path: '',
      component: () => import('./views/inbox.vue'),
      name: 'workbench-home',
      meta: {
        title: 'workbench',
        module: 'workbench',
      },
      beforeEnter() {
        return { path: '/workbench/inbox' }
      },
      children: [],
    },
    {
      path: 'document-editor',
      component: () => import('./views/editor.vue'),
      name: 'workbench-document-editor',
      meta: {
        title: 'workbench-document-editor',
        module: 'workbench',
        fullpage: true,
      },
    },
    {
      path: 'inbox',
      component: () => import('./views/providers/inbox-url-state-provider.vue'),
      name: 'workbench-inbox-url-state-provider',
      meta: {
        title: 'workbench-inbox',
        module: 'workbench',
        type: 'inbox',
      },
      children: [
        {
          path: '',
          component: () => import('./views/inbox.vue'),
          name: 'workbench-inbox',
          meta: {
            title: 'workbench-inbox',
            module: 'workbench',
            type: 'inbox',
          },
        },
        {
          path: 'd/:document',
          component: () => import('./views/document.vue'),
          name: 'workbench-inbox-document',
          meta: {
            title: 'workbench-inbox-document',
            module: 'workbench',
            type: 'inbox',
          },
        },
      ],
    },
    {
      path: 'drafts',
      component: () => import('./views/providers/drafts-url-state-provider.vue'),
      name: 'workbench-drafts-url-state-provider',
      meta: {
        title: 'workbench-drafts',
        module: 'workbench',
        type: 'drafts',
      },
      children: [
        {
          path: '',
          component: () => import('./views/drafts.vue'),
          name: 'workbench-drafts',
          meta: {
            title: 'workbench-drafts',
            module: 'workbench',
            type: 'drafts',
          },
        },
        {
          path: 'd/:document',
          component: () => import('./views/document.vue'),
          name: 'workbench-drafts-document',
          meta: {
            title: 'workbench-drafts-document',
            module: 'workbench',
            type: 'drafts',
          },
        },
      ],
    },
    {
      path: 'sent',
      component: () => import('./views/providers/sent-url-state-provider.vue'),
      name: 'workbench-sent-url-state-provider',
      meta: {
        title: 'workbench-sent',
        module: 'workbench',
        type: 'sent',
      },
      children: [
        {
          path: '',
          component: () => import('./views/sent.vue'),
          name: 'workbench-sent',
          meta: {
            title: 'workbench-sent',
            module: 'workbench',
            type: 'sent',
          },
        },
        {
          path: 'd/:document',
          component: () => import('./views/document.vue'),
          name: 'workbench-sent-document',
          meta: {
            title: 'workbench-sent-document',
            module: 'workbench',
            type: 'sent',
          },
        },
      ],
    },
    {
      path: 'trash',
      component: () => import('./views/providers/trash-url-state-provider.vue'),
      name: 'workbench-trash-url-state-provider',
      meta: {
        title: 'workbench-trash',
        module: 'workbench',
        type: 'trash',
      },
      children: [
        {
          path: '',
          component: () => import('./views/trash.vue'),
          name: 'workbench-trash',
          meta: {
            title: 'workbench-trash',
            module: 'workbench',
            type: 'trash',
          },
        },
        {
          path: 'd/:document',
          component: () => import('./views/document.vue'),
          name: 'workbench-trash-document',
          meta: {
            title: 'workbench-trash-document',
            module: 'workbench',
            type: 'trash',
          },
        },
      ],
    },
    {
      path: 'd/:document',
      component: () => import('./views/document.vue'),
      name: 'workbench-document',
      meta: {
        title: 'workbench-document',
        module: 'workbench',
      },
    },
    {
      path: 'address-book',
      component: () => import('./views/address-book.vue'),
      name: 'workbench-address-book',
      meta: {
        title: 'workbench-address-book',
        module: 'workbench',
      },
    },
    {
      path: 'calendar',
      component: () => import('./views/calendar.vue'),
      name: 'workbench-calendar',
      meta: {
        title: 'workbench-calendar',
        module: 'workbench',
      },
    },
    {
      path: 'my-office',
      component: () => import('./views/my-office.vue'),
      name: 'workbench-office',
      meta: {
        title: 'workbench-office',
        module: 'workbench',
      },
    },

    // {
    //   path: 'split',
    //   name: 'workbench-split',
    //   component: () => import('./views/documents-split.vue'),
    //   meta: {
    //     title: 'keyflowz-split',
    //     module: 'workbench',
    //   },
    // },
  ],
}
