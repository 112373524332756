import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import AlertCircle from '~icons/epb-icons/alert-circle'

import { ActionCondition, Parser, RolesCondition } from '@epostbox/permissions'
import { toast } from '@epostbox/ui/sonner'

import { useUser } from './use-user'

interface Options {
  redirectToURL: string
}

export function useCan() {
  const { user } = useUser()
  const router = useRouter()
  const { t } = useI18n()

  return (args: Pick<RolesCondition, 'roles'> | Pick<ActionCondition, 'action'>, options?: Options) => {
    if (!user.value) {
      if (options?.redirectToURL) {
        router.push('/')
        toast.error(t('common.not_allowed_to access_page'), {
          icon: AlertCircle,
        })
      }
      return
    }

    const canAccess = Parser.can(
      {
        ...args,
        // @ts-expect-error ulid types
        userId: user.value.id,
        // @ts-expect-error ulid types
        workspaceId: user.value.workspaceId,
      },
      user.value.role
    )

    if (!canAccess && options?.redirectToURL) {
      router.push(options?.redirectToURL).then(() => {
        toast.error(t('common.not_allowed_to access_page'), {
          icon: AlertCircle,
        })
      })
    } else {
      return canAccess
    }
  }
}

export function useAdmin(options?: Options) {
  const can = useCan()
  return can({ roles: ['Admin', 'Workspace Admin'] }, options)
}
