import {
  IncomingPermissions,
  IncomingPolicy,
  OutgoingChannel,
  OutgoingPermissions,
  OutgoingPolicy,
  RoleRecord,
} from '@epostbox/db/schema'

import { ActionCondition, isCondition, RolesCondition } from './types'

export function can(args: ActionCondition | RolesCondition, userRole: RoleRecord) {
  if (isCondition.role(args)) {
    return typeof args.roles === 'string' ? userRole.name === args.roles : args.roles.includes(userRole.name)
  }

  if (isCondition.action(args)) {
    if (userRole.permissions.action === '*') return true

    if (!args.action) return true

    return !(userRole.permissions.effect === 'deny' && userRole.permissions.action.includes(args.action))
  }

  return false
}

export type DocumentCanPayload = Pick<OutgoingPolicy, 'signatures' | 'letterheads'> & {
  permission: keyof OutgoingPermissions | keyof IncomingPermissions
  channel?: OutgoingChannel
}

export function canOnDocument<T extends IncomingPolicy | OutgoingPolicy>(args: DocumentCanPayload, accessPolicy: T) {
  const permissions = accessPolicy.permissions
  const hasPermission = permissions?.[args.permission as keyof typeof permissions] === true

  if ('signatures' in accessPolicy) {
    return args.signatures?.every(sign => accessPolicy.signatures?.includes(sign))
  }

  if ('letterheads' in accessPolicy) {
    return args.letterheads?.every(sign => accessPolicy.letterheads?.includes(sign))
  }

  if ('channels' in accessPolicy) {
    return args.channel && accessPolicy.channels?.includes(args.channel)
  }

  return hasPermission
}
