import { z } from 'zod'

import { PreprocessingStepName } from '../schema/documents'

export namespace PreprocessingQueue {
  export const MessagePayload = z.object({
    key: z.string(),
    bucket: z.string(),

    // Preprocessing Tasks to be executed
    tasks: z.array(PreprocessingStepName.exclude(['CONVERT_TO_PDF'])),
  })

  export type MessagePayload = z.infer<typeof MessagePayload>
}
