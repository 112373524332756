<script setup lang="ts">
import { watchEffect } from 'vue'
import { useLocalePath } from 'vue-i18n-routing'

import { Button } from '@epostbox/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@epostbox/ui/dropdown-menu'
import { cn } from '@epostbox/ui/utils'

const localePath = useLocalePath()

export interface Item {
  id: string
  label: string
  icon?: unknown
  url?: string
  type?: string
  disabled?: boolean
  callback?: () => void
}

withDefaults(
  defineProps<{
    items: Item[]
    isStatic?: boolean
    contentClasses?: string
    itemClasses?: string
    align?: 'center' | 'start' | 'end'
    dataE2e?: string
  }>(),
  {
    isStatic: false,
    contentClasses: '',
    itemClasses: '',
    align: 'end',
    dataE2e: '',
  }
)
defineEmits<{
  'click-item': [value: string]
}>()

const open = defineModel<boolean>('open', { default: false })

watchEffect(() => {
  const element = document.querySelector('.dropdown-menu-wrapper')
  if (open.value) {
    element?.classList.add('!overflow-hidden')
  } else {
    element?.classList.remove('!overflow-hidden')
  }
})
</script>

<template>
  <div>
    <DropdownMenu v-model:open="open">
      <DropdownMenuTrigger as-child>
        <slot />
      </DropdownMenuTrigger>
      <DropdownMenuContent :class="cn('w-fit', contentClasses)" :align="align" hide-when-detached avoid-collisions>
        <DropdownMenuItem
          v-for="(item, index) in items"
          :key="item.id"
          as-child
          class="cursor-pointer"
          :data-e2e="`${dataE2e}-${item.id}`"
        >
          <Button
            v-if="isStatic"
            :as="item.url ? 'router-link' : 'button'"
            variant="transparent"
            :disabled="item.disabled"
            :class="cn('h-[46px] w-full justify-start rounded-none bg-white text-left hover:bg-gray-200', itemClasses)"
            @click="$emit('click-item', item.id)"
          >
            <component :is="item.icon" v-if="item.icon" class="mr-2 h-4 w-4" />
            <span> {{ $t(item.label) }}</span>
          </Button>
          <Button
            v-else
            :as="item.url ? 'router-link' : 'button'"
            :class="
              cn('h-[46px] w-full justify-start !gap-2 rounded-none bg-white text-left hover:bg-gray-200', itemClasses)
            "
            :disabled="item.disabled"
            variant="transparent"
            v-bind="item.url ? { to: localePath(item.url) } : {}"
            v-on="item.callback ? { click: item.callback } : {}"
          >
            <component :is="item.icon" v-if="item.icon" class="pointer-events-none h-5 w-5 min-w-5 text-gray-350" />
            <div class="pointer-events-none flex items-center gap-x-2.5">
              <span v-if="item.type" class="mr-4 font-medium text-gray-600"> {{ `${item.type}  ${index + 1}` }}</span>
              <span class="mr-4"> {{ $t(item.label) }}</span>
            </div>
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>
