import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'
import { MaybeRef, Ref } from 'vue'

import { Space, spaceEnum } from '@epostbox/db/schema'
import type { Document } from '@epostbox/db/search'
import { ServiceError } from '@epostbox/shared/errors'

import { facetsQuery } from '@composables/search/use-facets'
import { useSearchClient } from '@composables/search/use-search-client'

import { Filter, FilterExclude } from '@modules/workbench/composables/use-table-state'

const documentsCollection = 'document_assignments'
const MISSING = 'MISSING'

export interface DocumentsSearchInput {
  q?: string
  page?: number
  limit?: number
  space?: Space
  filterBy?: Filter
  filterByExclude?: FilterExclude
  sortBy?: string
}

function mapChildrenToParents(
  parents: SearchResponseHit<Document>[] | undefined,
  children: SearchResponseHit<Document>[] | undefined
) {
  return parents?.map(parent => {
    if (!parent.document.groupKey || parent.document.groupKey === MISSING) return parent

    return {
      ...parent,
      document: {
        ...parent.document,
        children: children
          ?.filter(child => child.document.groupKey === parent.document.groupKey)
          .map(child => child.document),
      },
    }
  })
}

export function useDocumentSearch(search: MaybeRef<DocumentsSearchInput>, options?: { refetch?: boolean }) {
  const { searchCreds, searchClient } = useSearchClient()

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const filterBy = (filters?: Filter) =>
    Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} := ${value}` : []))

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const filterByExclude = (filters?: FilterExclude) =>
    Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} :!= ${value}` : []))

  function findParents(_search: DocumentsSearchInput) {
    const parent_filters = [
      `groupKey := ${MISSING}`, // has no group, so implicitly it's a parent
      `isGroupParent := true`, // has a group and it's also the parent
    ]

    const filter_by = [
      `space := ${_search.space ?? 'DRAFTS'}`,
      'hidden := false',
      ...filterBy(_search.filterBy as Filter),
      ...filterByExclude(_search.filterByExclude as FilterExclude),
      `(${parent_filters.join(' || ')})`,
    ]
      .flat()
      .join(' && ')

    return searchClient
      .value!.collections<Document>(documentsCollection)
      .documents()
      .search(
        {
          q: _search.q ?? '*',
          per_page: _search.limit,
          page: _search.page,
          query_by: 'name, subject, sender.name',
          filter_by,
          num_typos: '1',
          infix: ['fallback', 'off', 'off'],
          sort_by: _search.sortBy,
          facet_by: facetsQuery.join(', '),
        },
        {}
      )
  }

  function findChildren(parents: Awaited<ReturnType<typeof findParents>>) {
    const groupKeys =
      parents.hits
        ?.map(hit => {
          if (hit.document?.groupKey === MISSING) return

          return `groupKey := '${hit.document.groupKey}'`
        })
        .filter(Boolean) || []

    if (groupKeys.length === 0) return

    const filter_by = `isGroupParent := false && (${groupKeys.join(' || ')})`

    return searchClient.value!.collections<Document>(documentsCollection).documents().search({
      q: '*',
      filter_by,
      per_page: 250,
    })
  }

  const {
    data: searchResult,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search', search] as const,
    enabled: () => !!searchCreds.value,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    // prettier-ignore
    refetchInterval: import.meta.env.DEV ? undefined : (options?.refetch ? 2000 : undefined),
    queryFn: async ({ queryKey: [, search] }) => {
      const parents = await findParents(search)
      const children = await findChildren(parents)
      const mappedHits = mapChildrenToParents(parents?.hits, children?.hits)

      return { ...parents, hits: mappedHits }
    },
  })

  return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useDocumentsCounts() {
  const { searchCreds, searchClient } = useSearchClient()

  const {
    data: documentsCounts,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search-counts'] as const,
    enabled: () => Boolean(searchCreds),
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    queryFn: async () => {
      const searchResult = await searchClient.value!.multiSearch.perform<Document[]>({
        searches: spaceEnum.enumValues.map<MultiSearchRequestSchema>(space => ({
          collection: documentsCollection,
          q: '*',
          filter_by: `space := ${space} && hidden := false ${space === 'INBOX' ? ' && status :!= PENDING' : ''}`,
          include_fields: '_', // we don't need any fields, they would just increase the size of the response
        })),
      })

      const [drafts, inbox, sent, trash, folder] = searchResult.results

      return {
        drafts: drafts.found,
        inbox: inbox.found,
        sent: sent.found,
        trash: trash.found,
        folder: folder.found,
      }
    },
    initialData: {
      drafts: 0,
      inbox: 0,
      sent: 0,
      trash: 0,
      folder: 0,
    },
  })

  return { documentsCounts, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useInvalidateDocuments() {
  const queryClient = useQueryClient()

  const invalidateDocuments = async () => {
    await Promise.allSettled([
      queryClient.invalidateQueries({ queryKey: ['search'] }),
      queryClient.invalidateQueries({ queryKey: ['search-counts'] }),
    ])
  }

  return { invalidateDocuments }
}
