import { useQuery } from '@tanstack/vue-query'
import { Client } from 'typesense'
import { SearchResponse, SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import { computed, Ref } from 'vue'

import { Document } from '@epostbox/db/search'
import { ServiceError } from '@epostbox/shared/errors'

import { useSearchKey } from './use-search-key'

export const facetsQuery = [
  'subclassification.secondaryLabel',
  'workflow.name',
  'channel',
  'classification',
  'mimeType',
]

type FacetKey = 'subclassification.secondaryLabel' | 'workflow.name' | 'channel' | 'classification' | 'mimeType'

export type Facets = {
  [key in FacetKey]: string[]
}

export function mapFacetsToObject(facets: SearchResponseFacetCountSchema<Document>[]): Facets {
  const _facets = facets || []

  return (
    _facets
      .map(entry => {
        const key = entry.field_name as FacetKey
        return {
          [key]: entry.counts.map(count => count.value),
        }
      })
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce(
        (final, current) => ({
          ...final,
          ...current,
        }),
        {}
      ) as Facets
  )
}

const documentsCollection = 'document_assignments'
export function useFacets() {
  const { searchCreds } = useSearchKey()

  const searchClient = computed(() => {
    if (!searchCreds.value) return

    const url = new URL(searchCreds.value.url)

    return new Client({
      nodes: [
        {
          host: url.hostname,
          port: url.protocol === 'https:' ? 443 : Number(url.port || 80),
          protocol: url.protocol.replace(':', ''),
          path: url.pathname,
        },
      ],
      apiKey: searchCreds.value.keys.documentKey,
      connectionTimeoutSeconds: 10,
    })
  })

  const {
    data: facets,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search', 'facets'],
    enabled: () => !!searchCreds.value,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    refetchInterval: 3000,
    queryFn: async () => {
      const result: SearchResponse<object> = await searchClient
        .value!.collections(documentsCollection)
        .documents()
        .search(
          {
            q: '*',
            facet_by: facetsQuery.join(', '),
            per_page: 0,
          },
          {}
        )

      const facets = mapFacetsToObject(result.facet_counts || [])

      return facets
    },
  })

  return {
    facets,
    error: error as Ref<ServiceError | null>,
    ...queryRest,
  }
}
