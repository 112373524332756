import { z } from 'zod'

import { UserID, WorkspaceID } from '@epostbox/db/domain'
import { RoleActions, RoleName } from '@epostbox/db/schema'

const BaseCondition = z.object({
  userId: UserID,
  workspaceId: WorkspaceID,
})
type BaseCondition = z.infer<typeof BaseCondition>
export const ActionCondition = BaseCondition.extend({ action: RoleActions.optional() })
export type ActionCondition = z.infer<typeof ActionCondition>

export const RolesCondition = BaseCondition.extend({ roles: z.array(RoleName).or(RoleName) })
export type RolesCondition = z.infer<typeof RolesCondition>

export const isCondition = {
  action: (condition: BaseCondition): condition is ActionCondition => ActionCondition.safeParse(condition).success,
  role: (condition: BaseCondition): condition is RolesCondition => RolesCondition.safeParse(condition).success,
}
