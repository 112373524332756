import { z } from '@hono/zod-openapi'

import { ProfileID } from './domain'
import {
  WorkflowInterfaceRecord,
  UserIdentityRecord,
  Triggers,
  WorkflowRecord,
  SenderConditions,
  RecipientConditions,
  AddressEntryRecord,
} from './schema'
import { CustomerDataRecord } from './schema/auth/customer-data'
import { EmailAccountRecord } from './schema/auth/email-accounts'
import { IdentityRecord } from './schema/auth/identity'
import { InterfaceFTPUserRecord } from './schema/auth/interface-ftp-user'
import { InterfaceRestApiUserRecord } from './schema/auth/interface-rest-api-user'
import { ScimTokenRecord } from './schema/auth/scim-token'
import { UserRecord, UserStatus } from './schema/auth/user'
import { WorkspaceRecord } from './schema/auth/workspace'
import { WorkspaceMemberRecord } from './schema/auth/workspace-member'

export {
  DocumentID,
  DocumentAssignmentID,
  AttachmentID,
  LetterheadID,
  SignatureID,
  AddressAssignmentID,
  AddressAssignmentSharingID,
  WorkflowID,
  WorkflowOutputID,
  WorkflowRunID,
  WorkflowInterfaceID,
  DocumentIdentifierID,
  GLAccountID,
  CreditorID,
} from './schema/_ids'

export {
  FlowStateID,
  UserID,
  WorkspaceID,
  WorkspaceMemberID,
  IdentityID,
  PermissionID,
  ProfileID,
  SAMLProviderID,
  SSODomainID,
  RefreshTokenID,
  SSOProviderID,
  SessionID,
  RoleID,
  UserRoleID,
  ScimTokenID,
  CustomerDataID,
  EmailAccountID,
  InterfaceFTPUserID,
  UserIdentityId,
  InterfaceRestApiUserID,
} from './schema/auth/_ids'

const PublicUser = UserRecord.pick({
  id: true,
  email: true,
  emailNew: true,
  isSuperAdmin: true,
  createdAt: true,
  updatedAt: true,
}).openapi('User')

const CustomerData = CustomerDataRecord.openapi('CustomerData')
const EmailAccounts = EmailAccountRecord.openapi('EmailAccounts')

const Workspace = WorkspaceRecord.openapi('Workspace')

const Profile = z.object({
  id: ProfileID,
  name: z.string(),
  mailboxPreference: z.enum(['postal', 'digital']).default('postal').optional(),
  profilePicture: z.string().nullish(),
  phoneNumber: z.string().nullish(),
  country: z.string().nullish(),
  city: z.string().nullish(),
  addressLine1: z.string().nullish(),
  addressLine2: z.string().nullish(),
  postalCode: z.string().nullish(),
  jobTitle: z.string().nullish(),
})

const User = PublicUser.extend({
  profile: Profile.omit({ id: true }).optional(),
  workspaces: z.array(Workspace.pick({ id: true, name: true })),
})

const WorkspaceMember = WorkspaceMemberRecord.extend({
  user: PublicUser.extend({ profile: Profile.nullish() }),
})

const ScimToken = ScimTokenRecord.extend({
  workspace: Workspace,
})

/** @internal */
const InternalUser = UserRecord.merge(WorkspaceMemberRecord.pick({ status: true })).extend({
  profile: Profile,
  identities: z.array(IdentityRecord),
  workspaces: z.array(WorkspaceRecord),
  userStatus: UserStatus.nullable(),
  userIdentity: UserIdentityRecord.nullable(),
  name: z.string().nullable().optional(),
  addressEntry: AddressEntryRecord.optional(),
})

const InterfaceFTPUser = InterfaceFTPUserRecord.pick({
  id: true,
  username: true,
  workspaceId: true,
  userId: true,
  createdAt: true,
  updatedAt: true,
})

const InterfaceRestApiUser = InterfaceRestApiUserRecord.pick({
  id: true,
  apiToken: true,
  expireAt: true,
  interfaceId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})

const WorkflowTriggers = Triggers.omit({ recipient: true, sender: true, signatureHash: true }).extend({
  sender: SenderConditions.optional(),
  recipient: RecipientConditions.optional(),
})

const Workflow = WorkflowRecord.omit({ stateMachine: true, triggers: true })
  .extend({
    triggers: WorkflowTriggers.nullable(),
  })
  .openapi('Workflow')

interface Workspace extends z.infer<typeof Workspace> {}
interface Identity extends z.infer<typeof IdentityRecord> {}
interface User extends z.infer<typeof User> {}
interface Profile extends z.infer<typeof Profile> {}
interface InternalUser extends z.infer<typeof InternalUser> {}
interface WorkspaceMember extends z.infer<typeof WorkspaceMember> {}
interface ScimToken extends z.infer<typeof ScimToken> {}
interface CustomerData extends z.infer<typeof CustomerData> {}
interface EmailAccounts extends z.infer<typeof EmailAccounts> {}
interface InterfaceFTPUser extends z.infer<typeof InterfaceFTPUser> {}
interface InterfaceRestApiUser extends z.infer<typeof InterfaceRestApiUser> {}
interface Workflow extends z.infer<typeof Workflow> {}
interface WorkflowInterface extends z.infer<typeof WorkflowInterfaceRecord> {}

export {
  Workspace,
  User,
  Identity,
  InternalUser,
  WorkspaceMember,
  Profile,
  ScimToken,
  CustomerData,
  EmailAccounts,
  InterfaceFTPUser,
  Workflow,
  WorkflowInterface,
  InterfaceRestApiUser,
}

export * from './utils/event-defs'
export * from './utils/queue-defs'

export * from './utils/ai-task'
export type { EInvoiceJSON, Step } from './schema'
