export const AccepteEmailMimeType = {
  pdf: 'application/pdf',
  ps: 'application/postscript',
  png: 'image/png',
  jpg: 'image/jpeg',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  rtf: 'application/rtf',
  txt: 'text/plain',
  csv: 'text/csv',
  xml: 'application/xml',
  xmlTxt: 'text/xml',
}

export const fileFormatsOptions = ['application/pdf', 'application/postscript', 'image/png'] as const

export enum FileMimeType {
  pdf = 'application/pdf',
  ps = 'application/postscript',
  png = 'image/png',
  jpg = 'image/jpeg',
  csv = 'text/csv',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  rtf = 'application/rtf',
  txt = 'text/plain',
  xml = 'application/xml',
  xmlText = 'text/xml',

  // Legacy Microsoft Office exported files (MS Office 1997-2003, pre-OpenXML format)
  legacyMsOffice = 'application/x-cfb',
}

export const ZugMimeType = 'application/pdf-zugferd'

export const AcceptedFileTypes = {
  images: [FileMimeType.png, FileMimeType.jpg],
  documents: [
    FileMimeType.pdf,
    FileMimeType.csv,
    FileMimeType.txt,
    FileMimeType.rtf,
    FileMimeType.doc,
    FileMimeType.docx,
    FileMimeType.ppt,
    FileMimeType.pptx,
    FileMimeType.xls,
    FileMimeType.xlsx,
    FileMimeType.ps,
    FileMimeType.xml,
    FileMimeType.xmlText,
  ],
}
